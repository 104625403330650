import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["buttonPrev", "buttonNext"];

  connect() {
    // segundo carousel
    this.sliderContainerFeedback = document.getElementById(
      "sliderContainerFeedback"
    );
    this.sliderFeedback = document.getElementById("sliderFeedback");
    this.cardsFeedback = sliderFeedback.getElementsByTagName("li");

    this.sliderContainerWidthFeedback =
      this.sliderContainerFeedback.clientWidth;
    this.elementToShowFeedback = 1;

    this.cardWidthFeedback =
      this.sliderContainerWidthFeedback / this.elementToShowFeedback;
    this.sliderFeedback.style.width =
      this.cardsFeedback.length * this.cardWidthFeedback + "px";
    this.sliderFeedback.style.transition = "margin";
    this.sliderFeedback.style.transitionDuration = "1s";

    this.buttonPrevTarget.classList.add("invisible");

    for (let index = 0; index < this.cardsFeedback.length; index++) {
      const element = this.cardsFeedback[index];
      element.style.width = this.cardWidthFeedback + "px";
    }
    // fim segundo carousel
  }

  nextFeedback() {
    if (
      +this.sliderFeedback.style.marginLeft.slice(0, -2) !=
      -this.cardWidthFeedback *
        (this.cardsFeedback.length - this.elementToShowFeedback)
    ) {
      this.buttonPrevTarget.classList.remove("invisible");
      this.sliderFeedback.style.marginLeft =
        +this.sliderFeedback.style.marginLeft.slice(0, -2) -
        this.cardWidthFeedback +
        "px";

      if (
        +this.sliderFeedback.style.marginLeft.slice(0, -2) ==
        -this.cardWidthFeedback *
          (this.cardsFeedback.length - this.elementToShowFeedback)
      ) {
        this.buttonNextTarget.classList.add("invisible");
      }
    }
  }

  prevFeedback() {
    this.buttonNextTarget.classList.remove("invisible");

    if (+this.sliderFeedback.style.marginLeft.slice(0, -2) != 0) {
      this.sliderFeedback.style.marginLeft =
        +this.sliderFeedback.style.marginLeft.slice(0, -2) +
        this.cardWidthFeedback +
        "px";

      if (+this.sliderFeedback.style.marginLeft.slice(0, -2) == 0) {
        this.buttonPrevTarget.classList.add("invisible");
      }
    }
  }
}
