import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["calendarBox"];

  connect() {}

  openCalendar() {
    document.body.classList.add("overflow-hidden");
    this.calendarBoxTarget.style.left = "200%";
    this.calendarBoxTarget.classList.remove("hidden");

    setTimeout(() => {
      this.calendarBoxTarget.style.left = "0";
    });
  }

  closeCalendar() {
    document.body.classList.remove("overflow-hidden");
    this.calendarBoxTarget.style.left = "0";

    setTimeout(() => {
      this.calendarBoxTarget.style.left = "200%";
    });

    setTimeout(() => {
      this.calendarBoxTarget.classList.add("hidden");
    }, 200);
  }
}
