import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.br";

// Connects to data-controller="imask"
// Docs: https://imask.js.org/guide.html

export default class extends Controller {
  static values = { pattern: String, rawId: String };
  static targets = ["rawValue"];

  connect() {
    this.mask = new Cleave(this.element, this.parseOptions());
  }

  disconnect() {
    this.mask?.destroy();
  }

  // TODO: melhorar isso pra suportar mais opções
  parseOptions() {
    if (this.patternValue === "whatsapp") {
      return this.whatsappPattern();
    } else if (this.patternValue === "currency") {
      return this.currencyPattern();
    } else if (this.patternValue === "cro") {
      return this.croPattern();
    } else if (this.patternValue === "sms") {
      return this.smsCodePattern();
    } else {
      return { mask: this.patternValue };
    }
  }

  whatsappPattern() {
    return {
      phone: true,
      phoneRegionCode: "BR",
      prefix: "+55",
    };
  }

  croPattern() {
    return {
      numericOnly: true,
      blocks: [14],
    };
  }

  smsCodePattern() {
    return {
      numericOnly: true,
      blocks: [4],
    };
  }

  currencyPattern() {
    return {
      numeral: true,
      prefix: "R$ ",
      rawValueTrimPrefix: true,
      numeralDecimalMark: ",",
      delimiter: ".",
      numeralPositiveOnly: true,
      onValueChanged: (e) => {
        //this.rawValueTarget.value = e.target.rawValue;
        // e.target = { value: '5100-1234', rawValue: '51001234' }
        console.log(e);
        const el = document.getElementById(this.rawIdValue);
        if (el) {
          el.value = e.target.rawValue;
        }
      },
    };
  }
}
