import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slide"];

  initialize() {
    this.index = 0;
    this.showCurrentSlide();
  }

  next() {
    this.index++;
    this.showCurrentSlide();

    if (this.index == this.slideTargets.length - 1) {
      this.index = -1;
    }
  }

  previous() {
    if (this.index == 0) {
      this.index = this.slideTargets.length;
    }

    this.index--;
    this.showCurrentSlide();
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index !== this.index;
    });
  }
}
