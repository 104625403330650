// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application";

// Register each controller with Stimulus
import controllers from "./**/*_controller.js";
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default);
});

import {
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover,
} from "tailwindcss-stimulus-components";
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

import Flatpickr from "stimulus-flatpickr";
application.register("flatpickr", Flatpickr);

import NestedForm from "stimulus-rails-nested-form";
application.register("nested-form", NestedForm);

import Lightbox from "stimulus-lightbox";
application.register("lightbox", Lightbox);

import PlacesAutocomplete from "stimulus-places-autocomplete";
application.register("places", PlacesAutocomplete);

import PasswordVisibility from "stimulus-password-visibility";
application.register("password-visibility", PasswordVisibility);
