import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "inputQuery",
    "btnLocation",
    "btnTeleconsultation",
    "searchBox",
    "inputTeleconsultation",
    "user",
  ];

  teleconsultation = false;
  cards = null;
  sliderContainer = null;
  slider = null;
  cardWidth = 0;
  clientWidth = 0;
  newLatitude = null;
  newLongtitude = null;

  inputFocusIn() {
    this.inputQueryTarget.classList.add("focus-in");
  }

  inputFocusOut() {
    this.inputQueryTarget.classList.remove("focus-in");
  }

  toggleTeleconsultation(event) {
    if (event.params.locationOnly) return;

    this.teleconsultation = !this.teleconsultation;
    this.inputTeleconsultationTarget.value = this.teleconsultation;

    if (this.teleconsultation) {
      this.searchBoxTarget.classList.add("is-teleconsultation");
      this.inputQueryTarget.focus();
    } else {
      this.searchBoxTarget.classList.remove("is-teleconsultation");
      this.inputQueryTarget.focus();
    }
  }

  focusIn() {
    document.getElementById("q").focus();
  }

  scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener("scroll", onScroll);
        callback();
      }
    };

    window.addEventListener("scroll", onScroll);
    onScroll();
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  }

  focusInputQuery() {
    const inputQueryElement = document.getElementById("q");
    const inputQueryOffsetTop = inputQueryElement.offsetTop;
    const tipPanel = document.getElementById("tip_panel");

    tipPanel.classList.add("hidden");
    tipPanel.classList.add("animate-tip-bounce");

    this.scrollTo(inputQueryOffsetTop, this.focusIn);

    tipPanel.classList.remove("hidden");

    setTimeout(function () {
      tipPanel.classList.remove("animate-tip-bounce");
    }, 2800);
  }
}
