import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {}

  filter(event) {
    let params = new URLSearchParams(window.location.search);

    params.set("location", event.params.location);
    params.set("latitude", event.params.latitude);
    params.set("longitude", event.params.longitude);
    params.set("common_specialities", false);

    let newUrl =
      window.location.origin +
      window.location.pathname +
      "?" +
      params.toString();
    window.history.replaceState({}, "", newUrl);

    window.location.href = newUrl;
  }
}
