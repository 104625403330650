import { Controller } from "@hotwired/stimulus";
import { removeElement } from "../helpers";

export default class extends Controller {
    remove(e) {
        e.preventDefault();
        if(confirm('Confirma exclusão?')){
            removeElement(this.element);
        }
    }
}