import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "title",
    "subtitle",
    "stepOne",
    "stepTwo",
    "stepThree",
    "stepFour",
    "stepOneMenu",
    "stepTwoMenu",
    "stepThreeMenu",
    "sidebar",
    "appointmentInsurance",
    "configs",
    "formStep",
    "privateInsurance",
    "insuranceSelection",
    "selectedInsurance",
    "appointmentConfigs",
    "dentalInsurance",
    "firstConsultation",
    "teleconsultation",
    "dentalInsuranceParticular",
    "notes",
    "termsOfService",
    "stepForms",
    "timeSlot",
    "patientId",
    "menu",
    "privacyTerms",
    "marketTerms",
    "loginNextStepButton",
    "cellphone",
    "emailUser",
    "nameUser",
    "createPasswordLink",
  ];

  title = "Finalize seu agendamento";
  passwordCreationUrl = "";

  updateDentalInsurance() {
    if (
      this.element.querySelector("#private_insurance") &&
      this.dentalInsuranceParticularTarget.checked
    ) {
      this.dentalInsuranceTarget.value = 1;
      this.insuranceSelectionTarget.classList.add("hidden");
    } else {
      this.dentalInsuranceTarget.value =
        this.selectedInsuranceTarget.value == 1
          ? null
          : this.selectedInsuranceTarget.value;
      this.insuranceSelectionTarget.classList.remove("hidden");
    }
  }

  updateConfig() {
    const first_consultation = this.element.querySelector(
      "#config_first_consultation"
    ).checked;
    const teleconsultation = this.element.querySelector(
      "#config_teleconsultation"
    ).checked;

    const config = { first_consultation, teleconsultation };
    this.configsTarget.value = JSON.stringify(config);
  }

  initialize() {
    this.subtitles = {
      0: "Informe os detalhes da consulta",
      1: "Informe seus dados pessoais",
      2: "Confirme a Consulta",
    };
    this.currentStep = 0;

    const local = localStorage.appointmentConfigs;
    if (local) {
      const previousLocal =
        JSON.parse(local).appointment_base.time_slot_id ==
        this.timeSlotTarget.value;
      if (previousLocal) {
        this.readAppointmentData();
      } else {
        localStorage.removeItem("appointmentConfigs");
        this.updateDentalInsurance();
        this.updateConfig();
      }
    } else {
      this.dentalInsuranceTarget.value = this.selectedInsuranceTarget.value;
      this.updateConfig();
      this.updateDentalInsurance();
    }
  }

  connect() {
    this.titleTarget.innerHTML = this.title;

    this.steps = this.element.querySelectorAll(
      "[data-appointments-target='step']"
    );
    this.menus = this.element.querySelectorAll(
      "[data-appointments-target='menu']"
    );

    this.showStep(this.currentStep);

    this.validUserData();
  }

  isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  isValidCellphone() {
    var cellphoneNumber = this.cellphoneTarget.value;
    var onlyNumbers = cellphoneNumber.replace(/\s/g, "");
    return onlyNumbers.length >= 13;
  }

  validUserData() {
    if (
      this.cellphoneTarget.value &&
      this.isValidCellphone() &&
      this.privacyTermsTarget.checked &&
      this.nameUserTarget.value &&
      this.emailUserTarget.value &&
      this.isValidEmail(this.emailUserTarget.value)
    ) {
      this.loginNextStepButtonTarget.removeAttribute("disabled");
    } else {
      this.loginNextStepButtonTarget.setAttribute("disabled", true);
    }
  }

  nextStep() {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep += 1;

      this.defineGoogleTagManagerLayer();

      if (this.currentStep == this.steps.length - 1) {
        const last_step = this.element.querySelectorAll("[id='step-4']");
        last_step[0].classList.remove("hidden");
        this.sidebarTarget.classList.add("hidden");
        this.subtitleTarget.classList.add("hidden");
        this.appointmentInsuranceTarget.classList.remove("hidden");
        this.titleTarget.innerHTML = "Consulta agendada!";
        if (this.createPasswordLinkTarget) {
          this.createPasswordLinkTarget.href = this.passwordCreationUrl;
        }

        localStorage.removeItem("appointmentConfigs");
      }

      this.showStep(this.currentStep);
    }
  }

  defineGoogleTagManagerLayer() {
    let parsed_appointment = JSON.parse(localStorage.appointmentConfigs);

    if (this.currentStep == this.steps.length - 2) {
      dataLayer.push({ event: "Step1Contato" });
    }

    if (this.currentStep == this.steps.length - 1) {
      dataLayer.push({
        teleConsulta: parsed_appointment.first_step.teleconsultation,
      });
      dataLayer.push({
        primeiraConsulta: parsed_appointment.first_step.first_consultation,
      });
      dataLayer.push({ consultaAgendada: true });
      dataLayer.push({ event: "Step2Confirmacao" });
    }
  }

  previousStep() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
      this.showStep(this.currentStep);
    } else {
      window.history.back();
    }
  }

  storeAppointmentData() {
    this.updateConfig();
    let configsField = document.getElementById("appointment-configs");
    let appointmentConfigs = JSON.parse(configsField.value.replace(/'/g, '"'));

    // first
    const privateInsuranceComponent =
      this.element.querySelector("#private_insurance");
    appointmentConfigs.first_step = JSON.parse(this.configsTarget.value);
    appointmentConfigs.first_step.dental_insurance = {
      dental_insurance_particular: privateInsuranceComponent
        ? this.dentalInsuranceParticularTarget.checked
        : null,
      dental_insurance_selected_text: privateInsuranceComponent
        ? ""
        : this.selectedInsuranceTarget.options[0].innerHTML,
      dental_insurance_id: this.dentalInsuranceTarget.value,
    };

    // second
    appointmentConfigs.second_step = {
      notes: this.notesTarget.value,
      terms_of_service: this.termsOfServiceTarget.value,
      market_terms: this.marketTermsTarget.checked,
      privacy_terms: this.privacyTermsTarget.checked,
      cellphone: this.cellphoneTarget.value,
      email: this.emailUserTarget.value,
      name: this.nameUserTarget.value,
    };

    const spanCellphone = this.element.querySelector('[id="cellphonePreview"]');
    if (spanCellphone) spanCellphone.textContent = this.cellphoneTarget.value;

    if (this.patientIdTarget)
      appointmentConfigs.appointment_base.patient_id =
        this.patientIdTarget.value;

    localStorage.setItem(
      "appointmentConfigs",
      JSON.stringify(appointmentConfigs)
    );
  }

  redirectLogin() {
    document.location.href = "/users/sign_in";
  }

  readAppointmentData() {
    let appointmentConfigs = JSON.parse(
      localStorage.getItem("appointmentConfigs")
    );
    this.fillAppointmentFields(appointmentConfigs);
  }

  fillAppointmentFields(configs) {
    // first_step
    this.firstConsultationTarget.checked =
      configs.first_step.first_consultation;
    this.teleconsultationTarget.checked = configs.first_step.teleconsultation;
    const config = {
      first_consultation: this.firstConsultationTarget.checked,
      teleconsultation: this.teleconsultationTarget.checked,
    };
    this.configsTarget.value = JSON.stringify(config);

    // dental_insurance
    if (this.element.querySelector("#private_insurance")) {
      this.dentalInsuranceParticularTarget.checked =
        configs.first_step.dental_insurance.dental_insurance_particular;
    }
    this.dentalInsuranceTarget.value =
      configs.first_step.dental_insurance.dental_insurance_id;
    this.selectedInsuranceTarget.value =
      configs.first_step.dental_insurance.dental_insurance_id;
    this.updateDentalInsurance();

    // second_step
    this.notesTarget.value = configs.second_step.notes;
    this.termsOfServiceTarget.value = configs.second_step.terms_of_service;
    this.marketTermsTarget.checked = configs.second_step.market_terms;
    this.privacyTermsTarget.checked = configs.second_step.privacy_terms;
    if (configs.second_step.email) {
      this.cellphoneTarget.value = configs.second_step.cellphone;
      this.emailUserTarget.value = configs.second_step.email;
      this.nameUserTarget.value = configs.second_step.name;
    }
  }

  setTerms() {
    let parsed_appointment = JSON.parse(localStorage.appointmentConfigs);
    parsed_appointment.second_step.market_terms =
      this.marketTermsTarget.checked;
    parsed_appointment.second_step.privacy_terms =
      this.privacyTermsTarget.checked;

    if (this.privacyTermsTarget.checked && this.marketTermsTarget.checked) {
      this.termsOfServiceTarget.value = true;
      parsed_appointment.second_step.terms_of_service = true;
    } else {
      this.termsOfServiceTarget.value = false;
      parsed_appointment.second_step.terms_of_service = false;
    }

    this.validUserData();
    localStorage.setItem(
      "appointmentConfigs",
      JSON.stringify(parsed_appointment)
    );
  }

  showStep(stepIndex) {
    this.formStepTargets.forEach((step, index) => {
      if (index === stepIndex) {
        step.classList.remove("hidden");
        this.menus[index].classList.add("active");
        this.subtitleTarget.innerHTML = this.subtitles[index];
      } else {
        step.classList.add("hidden");
        this.menus[index].classList.remove("active");
      }
    });

    if (stepIndex === this.steps.length - 1) {
      this.titleTarget.innerHTML = "Consulta agendada!";
    } else {
      this.titleTarget.innerHTML = this.title;
    }
  }

  async submitForm(event) {
    const currentStepForm = event.target.closest("form");
    const formData = new FormData(currentStepForm);
    let method = currentStepForm.method;
    let action = currentStepForm.action;

    const local_appointment = localStorage.getItem("appointmentConfigs");
    if (local_appointment) {
      const local = JSON.parse(local_appointment);
      if (local.appointment_base.id) {
        (method = "PATCH"), (action = `/a/${local.appointment_base.id}`);
      }
    }

    try {
      const response = await fetch(action, {
        method: method,
        body: formData,
        headers: {
          "x-CSRF-Token": document.querySelector("meta[name='csrf-token']")
            .content,
        },
      });
      const data = await response.json();

      if (response.ok) {
        this.storeAppointmentData();
        let parsed_appointment = JSON.parse(localStorage.appointmentConfigs);
        parsed_appointment.appointment_base.id = data.id;
        if (data.patient_id) {
          parsed_appointment.appointment_base.patient_id = data.patient_id;
          this.patientIdTarget.value = data.patient_id;
        }
        parsed_appointment.appointment_base.password_creation_url =
          data.password_creation;
        this.passwordCreationUrl ||= data.password_creation;

        if (this.createPasswordLinkTarget) {
          this.createPasswordLinkTarget.href = this.passwordCreationUrl;
        }

        localStorage.setItem(
          "appointmentConfigs",
          JSON.stringify(parsed_appointment)
        );
        this.clearErrorFields(currentStepForm);

        this.nextStep();
      } else {
        if (data.errors) {
          this.displayErrors(data.errors, currentStepForm);
        } else {
          console.error("Request failed:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  }

  async resendSMS(event) {
    event.preventDefault();
    const config = JSON.parse(localStorage.getItem("appointmentConfigs"));
    const appointment_id = config.appointment_base.id;

    try {
      this.element.querySelector("#smsResendError").classList.add("hidden");
      this.element.querySelector("#smsResendOk").classList.add("hidden");
      const response = await fetch(`/a/${appointment_id}/resend-sms-code`, {
        method: "GET",
      });

      if (response.ok) {
        this.element.querySelector("#smsResendOk").classList.remove("hidden");
      } else {
        this.element
          .querySelector("#smsResendError")
          .classList.remove("hidden");
      }
    } catch (error) {
      this.element.querySelector("#smsResendError").classList.remove("hidden");
    }
  }

  displayErrors(errors, currentStepForm) {
    for (const [fieldName, _fieldErrors] of Object.entries(errors)) {
      const inputField = currentStepForm.querySelector(
        `[name="appointment[${fieldName}]"`
      );
      if (inputField) {
        inputField.classList.add("border-red-500");
      }

      if (fieldName == "time_slot") {
        this.element.querySelector("#slotError").classList.remove("hidden");
      }

      if (fieldName == "user_email") {
        this.element
          .querySelector("#patientAlreadyExistError")
          .classList.remove("hidden");
      }

      if (fieldName == "attempted_otp_code") {
        this.element
          .querySelector("#invalidOtpCode")
          .classList.remove("hidden");
      }
    }
  }

  // TODO refactor
  clearErrorFields(currentStepForm) {
    this.element.querySelector("#smsResendError").classList.add("hidden");
    this.element.querySelector("#smsResendOk").classList.add("hidden");
    this.element
      .querySelector("#patientAlreadyExistError")
      .classList.add("hidden");
    this.element.querySelector("#invalidOtpCode").classList.add("hidden");

    const errorElements = currentStepForm.querySelectorAll(".border-red-500");
    errorElements.forEach((element) => {
      element.classList.remove("border-red-500");
    });
  }
}
