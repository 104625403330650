import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["urlTag", "urlValue"];

  mask(event) {
    const inputChar = String.fromCharCode(event.charCode);
    const pattern = /^[a-z0-9-]+$/i; // Expressão regular para aceitar letras, números e hífens

    if (!pattern.test(inputChar)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  updateUrl(event) {
    this.urlTagTarget.innerHTML =
      "https://dentalconsulta.com.br/d/" +
      this.urlValueTarget.value.toLowerCase();
  }

  connect() {
    this.updateUrl();
  }
}
