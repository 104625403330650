import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "password",
    "length",
    "lengthIcon",
    "lowercase",
    "uppercase",
    "number",
    "specialChar",
  ];

  connect() {
    this.validatePassword();
  }

  validatePassword() {
    const password = this.passwordTarget.value;

    const rules = {
      length: password.length >= 10,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[^A-Za-z0-9]/.test(password),
    };

    for (const rule in rules) {
      this.targets.find(rule).classList.toggle("!text-secondary", rules[rule]);
    }
  }
}
