// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  reconnect(event) {
    if (consumer.connection.isActive()) {
      consumer.connection.reopen();
    }
  }

  newInput(event) {
    event.preventDefault();
    var divExistente = document.querySelector(".input-div");

    if (divExistente) {
      // Clonar a div existente
      var novaDiv = divExistente.cloneNode(true);

      // Adicionar a nova div com os inputs ao container
      var container = document.getElementById("input-container");
      if (container) {
        container.appendChild(novaDiv);
      } else {
        console.error("Elemento com ID 'input-container' não encontrado.");
      }
    } else {
      console.error("Div existente não encontrada.");
    }
  }
}
