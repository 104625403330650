import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = [
    "user",
    "date",
    "insuranceCaret",
    "specialityCaret",
    "dataCaret",
    "symptomsCaret",
    "boxFilter",
    "title",
    "scrollUp",
    "scrollDown",
    "boxFilters",
    "checkboxTeleconsultation",
    "form",
    "startDate",
    "endDate",
    "commonCities",
    "searchLocationInput",
  ];

  openInsurance() {
    this.insuranceCaretTarget.classList.toggle("rotate-180");
  }

  openSpeciality() {
    this.specialityCaretTarget.classList.toggle("rotate-180");
  }

  openData() {
    this.dataCaretTarget.classList.toggle("rotate-180");
  }

  openSymptoms() {
    this.symptomsCaretTarget.classList.toggle("rotate-180");
  }

  toggleTeleconsultation(_event) {
    this.checkboxTeleconsultationTarget.value =
      !this.checkboxTeleconsultationTarget.value;
  }

  toggleText(event) {
    const divElement = event.target.closest("div");
    const iconElement = divElement.querySelector("i");
    const textElement = divElement.querySelector("p");

    if (textElement.classList.contains("line-clamp-none")) return;

    textElement.classList.toggle("line-clamp-1");
    iconElement.classList.toggle("rotate-180");
  }

  handleSubmitionFilter = async (_event) => {
    const searchForm = document.getElementById("form-filters");

    if (searchForm) {
      const queryInput = searchForm.querySelector("#q").value;
      const location = searchForm.querySelector("#location").value;
      const teleconsultation =
        searchForm.querySelector("#teleconsultation").checked;
      const specialities = searchForm.querySelector("#specialities").value;
      const insurances = searchForm.querySelector("#insurances").value;
      const symptoms = searchForm.querySelector("#symptoms").value;
      const startDate = searchForm.querySelector("#start_date").value;
      const endDate = searchForm.querySelector("#end_date").value;
      const latitude = searchForm.querySelector("#input_latitude").value;
      const longitude = searchForm.querySelector("#input_longitude").value;

      const newUrl = new URL(window.location);
      newUrl.searchParams.set("q", queryInput);
      newUrl.searchParams.set("location", location);
      newUrl.searchParams.set("teleconsultation", teleconsultation);
      newUrl.searchParams.set("specialities", specialities);
      newUrl.searchParams.set("insurances", insurances);
      newUrl.searchParams.set("symptoms", symptoms);
      newUrl.searchParams.set("start_date", startDate);
      newUrl.searchParams.set("end_date", endDate);
      newUrl.searchParams.set("input_latitude", latitude);
      newUrl.searchParams.set("input_longitude", longitude);

      history.pushState(null, "", newUrl);
      const submitButton = searchForm.querySelector("#submit-filters");
      submitButton.click();
    }
  };

  resetFilterResult(id) {
    const newUrl = new URL(window.location);
    const searchForm = document.getElementById("form-filters");
    newUrl.searchParams.set(id, "");
    history.pushState(null, "", newUrl);

    const submitButton = searchForm.querySelector("#submit-filters");
    submitButton.click();
  }

  connect() {
    const accounts = JSON.parse(
      this.element.getAttribute("data-accounts-value")
    );
    if (accounts) this.changeTab(accounts[0]);

    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    window.addEventListener("scroll", this.listenScrollEvent);

    let urlParams = new URLSearchParams(window.location.search);
    let location = urlParams.get("location");

    if (location) {
      this.commonCitiesTarget.classList.add("hidden");
    }
  }

  disconnect() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  listenScrollEvent() {
    if (window.scrollY > 58) {
      const filterHeight = this.boxFilterTarget.offsetHeight;
      this.boxFilterTarget.classList.add("bg-white", "z-30", "drop-shadow-md");
    } else {
      this.boxFilterTarget.classList.remove(
        "bg-white",
        "z-30",
        "drop-shadow-md"
      );
    }
  }

  today() {
    const date = new Date();
    this.startDateTarget.value = date.toISOString();
    this.endDateTarget.value = date.toISOString();
    this.dateTarget.value =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      " - " +
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2);
  }

  threeDays() {
    const date = new Date();

    this.dateTarget.value =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      " - " +
      (date.getDate() + 3) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2);

    this.startDateTarget.value = date.toISOString();
    date.setDate(date.getDate() + 3);
    this.endDateTarget.value = date.toISOString();
  }

  allDays() {
    this.startDateTarget.value = null;
    this.endDateTarget.value = null;
    this.dateTarget.value = "Qualquer Data";
  }

  changeTab(event) {
    event.preventDefault();
    event.stopPropagation();
    Turbo.visit(event.params.location, { frame: event.params.frame });
  }

  submitAdvancedFilters() {
    this.formTarget.requestSubmit();
  }

  locationFocusIn() {
    this.searchLocationInputTarget.focus();

    const instance = tippy(document.querySelector("#location"), {
      content: "Digite o local desejado aqui",
    });

    instance.show();
  }
}
