import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formControl", "formGroup", "errorText"];

  isValidEmail() {
    const emailRegex = /^[a-zA-Z0-9._\-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(this.formControlTarget.value);
  }

  checkEmail() {
    if (this.isValidEmail()) {
      this.formControlTarget.classList.remove("error");
      this.errorTextTarget.classList.add("hidden");
    } else {
      this.formControlTarget.classList.add("error");
      this.errorTextTarget.classList.remove("hidden");
    }
  }
}
