import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "icon"];

  connect() {
    //  Verifica se o texto foi truncado para exibir o link de "ver mais"
    if (this.contentTarget.scrollHeight > this.contentTarget.clientHeight) {
      this.iconTarget.classList.remove("!hidden");
    }
  }

  toggleContent(event) {
    this.contentTarget.classList.toggle(event.params.class);
    this.iconTarget.classList.toggle("rotate-180");
  }
}
