import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  setDarkMode() {
    this.buttonTarget.classList.remove("fa-moon");
    this.buttonTarget.classList.add("fa-sun");
    localStorage.setItem("theme", "dark");
  }

  setLightMode() {
    this.buttonTarget.classList.remove("fa-sun");
    this.buttonTarget.classList.add("fa-moon");
    localStorage.setItem("theme", "light");
  }

  connect() {
    var currentTheme = localStorage.getItem("theme");

    if (currentTheme == "dark") {
      document.documentElement.classList.add("dark");
      this.setDarkMode();
    } else {
      document.documentElement.classList.remove("dark");
      this.setLightMode();
    }
  }

  toggleDarkMode() {
    document.documentElement.classList.toggle("dark");

    if (document.documentElement.classList.contains("dark")) {
      this.setDarkMode();
    } else {
      this.setLightMode();
    }
  }
}
