import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "removeLabel",
    "removeField",
    "elementNested"
  ];

  connect() { }

  // TODO realocate that method
  removeNestedElement(event) {
    event.preventDefault();

    const removeLabel = this.removeLabelTarget;
    const removeField = this.removeFieldTarget;

    removeLabel.classList.toggle("hidden");
    this.elementNestedTarget.classList.add("hidden");
    removeField.value = "1";
  }
}
