import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formControl", "formGroup", "errorText"];

  connect() {
    this.focusOut = 0;
  }

  isValidCellphone() {
    var cellphoneNumber = this.formControlTarget.value;
    var onlyNumbers = cellphoneNumber.replace(/\s/g, "");
    return onlyNumbers.length >= 13;
  }

  checkCellphone(event) {
    if (event.type == "focusout") {
      this.focusOut++;
    }

    if (this.focusOut == 0) return;

    if (this.isValidCellphone()) {
      this.formControlTarget.classList.remove("error");
      this.errorTextTarget.classList.add("hidden");
    } else {
      this.formControlTarget.classList.add("error");
      this.errorTextTarget.classList.remove("hidden");
    }
  }
}
