import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["buttonPrev", "buttonNext"];

  connect() {
    this.sliderContainer = document.getElementById("sliderContainer");
    this.slider = document.getElementById("slider");
    this.cards = this.slider.getElementsByTagName("li");

    this.sliderContainerWidth = this.sliderContainer.clientWidth;
    this.elementToShow = 6;

    this.clientWidth = 0;

    if (document.body.clientWidth < 900) {
      this.elementToShow = 4;
    }

    if (document.body.clientWidth < 600) {
      this.elementToShow = 2;
    }

    this.buttonPrevTarget.classList.add("hidden");

    this.cardWidth = this.sliderContainerWidth / this.elementToShow;
    this.slider.style.width = this.cards.length * this.cardWidth + "px";
    this.slider.style.transition = "margin";
    this.slider.style.transitionDuration = "1s";

    for (let index = 0; index < this.cards.length; index++) {
      const element = this.cards[index];
      element.style.width = this.cardWidth + "px";
    }
  }

  next() {
    if (
      +this.slider.style.marginLeft.slice(0, -2) !=
      -this.cardWidth * (this.cards.length - this.elementToShow)
    ) {
      this.buttonPrevTarget.classList.remove("hidden");
      this.slider.style.marginLeft =
        +this.slider.style.marginLeft.slice(0, -2) - this.cardWidth + "px";

      if (
        +this.slider.style.marginLeft.slice(0, -2) ==
        -this.cardWidth * (this.cards.length - this.elementToShow)
      ) {
        this.buttonNextTarget.classList.add("hidden");
      }
    }
  }

  prev() {
    this.buttonNextTarget.classList.remove("hidden");

    if (+this.slider.style.marginLeft.slice(0, -2) == this.cardWidth * -1) {
      this.buttonPrevTarget.classList.add("hidden");
    }

    if (+this.slider.style.marginLeft.slice(0, -2) != 0) {
      this.slider.style.marginLeft =
        +this.slider.style.marginLeft.slice(0, -2) + this.cardWidth + "px";
    }
  }
}
