import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["buttonUp", "buttonDown"];

  scrollDownCount = 1;
  scrollUpCount = 1;

  connect() {
    this.buttonUpTarget.setAttribute("disabled", true);
  }

  scrollDown(event) {
    this.buttonUpTarget.removeAttribute("disabled");

    var cellHeight =
      document.getElementsByClassName("hour-item-box")[0].offsetHeight;

    var scrollSize = cellHeight * 6;
    var hoursBox = document.getElementById("hours_box_" + event.params.userId);
    var hoursBoxHeight = hoursBox.offsetHeight;

    var actualScroll = hoursBox.offsetTop;

    hoursBox.scrollTo({
      top: this.scrollDownCount * scrollSize,
      behavior: "smooth",
    });

    this.scrollDownCount++;
    console.log(hoursBoxHeight / cellHeight / 6);

    if (actualScroll >= hoursBoxHeight) {
      this.buttonDownTarget.setAttribute("disabled", true);
    }
  }

  scrollUp(event) {
    this.buttonDownTarget.removeAttribute("disabled");
    this.scrollDownCount--;

    console.log(this.scrollDownCount, "count");

    var cellHeight =
      document.getElementsByClassName("hour-item-box")[0].offsetHeight;

    var scrollSize = cellHeight * 6;
    var hoursBox = document.getElementById("hours_box_" + event.params.userId);

    var actualScroll = this.scrollDownCount * scrollSize;

    hoursBox.scrollTo({
      top: (actualScroll -= scrollSize),
      behavior: "smooth",
    });

    if (this.scrollDownCoun <= 0) {
      this.buttonUpTarget.setAttribute("disabled", true);
    }
  }
}
