import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["files", "fileInput", "previewContainer"]

  connect() {
    this.fileInputTarget.addEventListener("change", this.handleFileChange.bind(this));
  }

  // TODO preview
  handleFileChange(event) {
    const files = event.target.files;
    
    for (const file of files) {
      const newImage = this.previewContainerTarget.cloneNode();
      const container = document.getElementById("filesContainer");
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.className = "h-16 w-16 rounded-lg object-cover object-center";

        newImage.appendChild(img);
        container.appendChild(newImage);
      };

      reader.readAsDataURL(file);
    }
  }

  async deleteOne(event) {
    event.preventDefault();
    const [_dentist_id, certificate_id] = event.target.parentElement.id.split("_");

    const url = new URL(`certificate/${certificate_id}`, window.location.origin);

    await fetch(url, {
      method: "DELETE",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": Rails.csrfToken()
      }
    }).then(response => {
    }).catch(error => {
      console.error("Error:", error);
    });
  }

  addFile(event) {
    const originalInput = event.target
    const originalParent = originalInput.parentNode

    this.filesTarget.append(originalInput)
    const newInput = originalInput.cloneNode()
    newInput.value = ""

    originalParent.append(newInput)
  }
}