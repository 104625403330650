import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "showMore"];
  static values = {
    rows: { type: Number, default: 4 },
  };

  connect() {
    this.lines = [
      "line-clamp-none",
      "line-clamp-1",
      "line-clamp-2",
      "line-clamp-3",
      "line-clamp-4",
      "line-clamp-5",
      "line-clamp-6",
    ];

    // Adiciona a classe para truncar o texto
    this.textTarget.classList.add(this.lines[this.rowsValue]);

    //  Verifica se o texto foi truncado para exibir o link de "ver mais"
    if (this.textTarget.scrollHeight > this.textTarget.clientHeight) {
      this.showMoreTarget.classList.remove("hidden");
    }
  }

  /**
   * Expande e retrai o texto
   */
  toggleText() {
    this.textTarget.classList.toggle(this.lines[this.rowsValue]);

    if (this.showMoreTarget.innerText == "Ver mais") {
      this.showMoreTarget.innerText = "Ver menos";
    } else {
      this.showMoreTarget.innerText = "Ver mais";
    }
  }
}
