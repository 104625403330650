import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["inputQuery", "suggestions", "field"];

  connect() {
    useClickOutside(this);
  }

  suggestions() {
    const query = this.inputQueryTarget.value;
    const url = this.element.dataset.suggestionsUrl;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.requestSuggestions(query, url);
    }, 250);
  }
  requestSuggestions(query, url) {
    if (query.length === 0) {
      // this.hideSuggestions();
      return;
    }
    // this.showSuggestions();

    fetch(url + "?q=" + query, {
      method: "GET",
      headers: {
        "xContent-Type": "application/json",
        "x-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
      },
    }).then((response) => {
      response.text().then((html) => {
        if (html === "") {
          this.hideSuggestions();
        } else {
          this.showSuggestions();
          document.body.insertAdjacentHTML("beforeend", html);
        }
      });
    });
  }

  clickOutside(event) {
    this.hideSuggestions();
  }
  showSuggestions() {
    this.suggestionsTarget.classList.remove("hidden");
  }
  hideSuggestions() {
    this.suggestionsTarget.classList.add("hidden");
  }

  selectOption(event) {
    const optionText = event.currentTarget.children[0].textContent;
    this.inputQueryTarget.value = optionText;
    console.log(event.params);
    switch (event.params.type) {
      case "speciality":
        this.fieldTarget.name = "speciality_ids[]";
        this.fieldTarget.value = event.params.id;
        break;
      case "dental_insurance":
        this.fieldTarget.name = "insurance_ids[]";
        this.fieldTarget.value = event.params.id;
        break;
      default:
        this.fieldTarget.value = null;
    }
  }
}
