import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchLocationInput", "latitude", "longitude"];

  options = {
    enableHighAccuracy: true,
    maximumAge: 0,
  };

  connect() {
    let urlParams = new URLSearchParams(window.location.search);
    let teleconsultation = urlParams.get("teleconsultation");
    let common_specialities = urlParams.get("common_specialities");

    if (teleconsultation === "true" || common_specialities === "true") {
      this.searchLocationInputTarget.value = "";
      return;
    }

    if (this.searchLocationInputTarget.value.length > 0) {
      return;
    }

    if (this.isGeolocationCached()) {
      this.loadGeolocationFromCache();
    } else {
      this.requestGeolocation();
    }
  }

  disconnect() {
    super.disconnect();
  }

  /**
   * Verifica se a latitude está salva no local storage
   *
   * @returns {boolean}
   */
  isGeolocationCached() {
    return !!localStorage.getItem("latitude");
  }

  /**
   * Carrega as informações através das coordenadas no local storage
   */
  loadGeolocationFromCache() {
    var lat = localStorage.getItem("latitude");
    var lng = localStorage.getItem("longitude");

    this.fillInputTargets(lat, lng);
    this.getLocation(lat, lng).then((data) => {
      this.searchLocationInputTarget.value = data.response.title;
    });
  }

  /**
   * Preenche os inputs com as coordenadas
   *
   * @param lat
   * @param lng
   */
  fillInputTargets(lat, lng) {
    this.latitudeTarget.value = lat;
    this.longitudeTarget.value = lng;
  }

  /**
   * Salva as coordenadas no Local Storage
   *
   * @param lat
   * @param lng
   */
  saveGeolocationToCache(lat, lng) {
    localStorage.setItem("latitude", lat);
    localStorage.setItem("longitude", lng);
  }

  /**
   * Solicita a localização do usuário
   *
   */
  requestGeolocation() {
    navigator.geolocation.getCurrentPosition(
      this.onGeolocationRequestSuccess.bind(this),
      this.onGeolocationRequestError,
      this.options
    );
  }

  /**
   * Salva em cache e carrega
   * @param position
   * @returns {Promise<void>}
   */
  async onGeolocationRequestSuccess(position) {
    let { latitude, longitude } = position.coords;

    this.saveGeolocationToCache(latitude, longitude);
    this.fillInputTargets(latitude, longitude);
  }

  onGeolocationRequestError(error) {
    console.error(error);
  }

  /**
   * Busca a Cidade/UF pelas coordenadas
   *
   * @param lat
   * @param lng
   * @returns {Promise<any>}
   */
  async getLocation(lat, lng) {
    const coordinates = { coordinates: [lat, lng] };
    const response = await fetch("/transform_coordinates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-CSRF-Token": document.querySelector("meta[name='csrf-token']")
          .content,
      },
      body: JSON.stringify(coordinates),
    });
    return await response.json();
  }

  /**
   * Quando uma localização é selecionada no compontente
   *
   * @param value
   */
  onPlaceChanged(value) {
    if (!value) {
      return;
    }

    let [lat, lng] = value.split(",");

    this.saveGeolocationToCache(lat, lng);
    this.fillInputTargets(lat, lng);
  }
}
