import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "speciality",
    "accountTab",
    "addressSelect",
    "specialityDescription",
  ];

  connect() {
    this.initFirstAccount();
  }

  initFirstAccount() {
    if (!this.accountTabTargets || this.accountTabTargets.length == 0) {
      return;
    }
    this.accountTabTargets[0].click();
  }

  markSpecialities(specialities) {
    var add = 0;
    var remove = 0;
    this.specialityTargets.forEach((e) => {
      if (!specialities.includes(parseInt(e.dataset.id, 10))) {
        console.log("add");
        add++;
        e.getElementsByTagName("span")[0].classList.add("line-through");
        e.getElementsByTagName("span")[1].classList.remove("hidden");
      } else {
        console.log("remove");
        remove++;
        e.getElementsByTagName("span")[0].classList.remove("line-through");
        e.getElementsByTagName("span")[1].classList.add("hidden");
      }

      if (remove == this.specialityTargets.length) {
        this.specialityDescriptionTarget.classList.add("hidden");
      } else {
        this.specialityDescriptionTarget.classList.remove("hidden");
      }
    });
  }

  changeTab(event) {
    event.preventDefault();
    event.stopPropagation();
    Turbo.visit(event.params.location, { frame: event.params.frame });
    this.markSpecialities(event.params.specialities);
  }

  accountChanged(event) {
    this.accountTabTargets.forEach((tab) => {
      if (parseInt(tab.dataset.accountId) === parseInt(event.target.value)) {
        tab.click();
      }
    });
  }
}
