/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"

import gtag from "./src/gtm"
import "chartkick/chart.js"

require("local-time").start()

// Start Rails UJS
Rails.start()

window.initAutocomplete = function () {
    const event = new Event('google-maps-callback', {
        bubbles: true,
        cancelable: true,
    })
    window.dispatchEvent(event)
}