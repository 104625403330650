import Dropdown from "stimulus-dropdown";

export default class extends Dropdown {
  static targets = ["menu", "checkbox", "selectedCount", "icon"];

  connect() {
    super.connect();
    this.addListeners();
    this.updateSelectedCount();
  }

  disconnect() {
    this.removeListeners();
    super.disconnect();
  }

  addListeners() {
    this.checkboxTargets.forEach((e) => {
      e.addEventListener("change", this.itemChanged.bind(this));
    });
  }

  removeListeners() {
    this.checkboxTargets.forEach((e) => {
      e.removeEventListener("change", this.itemChanged.bind(this));
    });
  }

  itemChanged() {
    this.updateSelectedCount();
  }

  updateSelectedCount() {
    this.selectedCountTarget.textContent = this.checkboxTargets.filter(
      (e) => e.checked
    ).length;
  }

  toggle(event) {
    this.iconTarget.classList.toggle("rotate-180");
    super.toggle();
  }

  hide(event) {
    super.hide(event);
  }

  clear() {
    this.checkboxTargets.forEach((e) => {
      e.checked = false;
    });
    this.updateSelectedCount();
  }
}
