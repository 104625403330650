import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rating-input"
export default class extends Controller {
  static targets = ['input', 'star']

  connect() {
    this.configureStars()
  }

  configureStars() {
    const starValues = [10, 20, 30, 40, 50];

    this.starTargets.forEach((el, index) => {
      el.dataset.value = starValues[index];
      el.dataset.index = index;
      el.addEventListener('click', this.onStarClick.bind(this))
      el.addEventListener('mouseover', this.onStarMouseOver.bind(this))
      el.addEventListener('mouseout', this.onStarMouseOut.bind(this))
    })
  }

  onStarClick(event) {
    const { value, index } = event.target.dataset
    this.inputTarget.value = value;
    this.onStarMouseOver(event);
  }

  onStarMouseOver(event) {
    const targetIndex = event.target.dataset.index
    this.starTargets.forEach((el, index) => {
      if (parseInt(index) <= parseInt(targetIndex)) {
        this.fillStar(el)
      }
    })
  }

  onStarMouseOut(event) {
    const targetIndex = event.target.dataset.index
    const targetValue = this.inputTarget.value || 0;

    this.starTargets.forEach((el, index) => {
      console.log(el.dataset.value, targetValue);
      if ((parseInt(el.dataset.value) > parseInt(targetValue))) {
        this.cleanStar(el);
      }
    })
  }

  fillStar(el) {
    el.classList.add('fa-solid')
    el.classList.remove('fa-regular')
  }

  cleanStar(el) {
    el.classList.add('fa-regular')
    el.classList.remove('fa-solid')
  }
}
